import React from 'react';
import "./CalenderMenu.css";

export const CalenderMenu = () => {
    return (
        <div className='CalenderMenu'>

        </div>
    );
}
